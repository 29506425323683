import React from "react";
import { Link} from "react-router-dom";

const TopCtaArea = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area">
        <div className="container">
          <div className="tp-cta-wrapper pt-105 pb-120">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-9">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25"  style={{ color: '#0EFDF3', fontSize: '20px'}}>
                    Calidad Impresa, Valor Real
                  </span>
                  <h2 className="tp-section-title">
                    Desde la concepción hasta la entrega final, garantizamos la excelencia que tu negocio merece. Confía en los expertos en impresión de Querétaro.
                  </h2>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-lg-end">
                <Link to="/contact">
                  <div className="tp-theme-btn d-flex align-items-center">
                    <div className="tp-btn-inner" >
                      <p className="mb-0"  style={{ color: '#0EFDF3' }}>Explora <br/>Nuestros Servicios</p>
                      <b>Contacta con Nosotros</b>
                      <span className="d-inline-block">
                        {/* SVG icons remain unchanged */}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- line  --> */}
          <div className="tp-border-line d-block mb-140">
            <hr />
          </div>
          {/* <!-- line  --> */}
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default TopCtaArea;
