import React from "react";
import { Link } from "react-router-dom";

const Feature = () => {
  return (
    <>
      {/* <!-- feature area start  --> */}
      <div className="tp-feature-area position-relative">
        <div
          className="tp-fe-bg d-none d-lg-block"
          style={{
            backgroundImage: `url(/assets/img/bg/bg-1.jpg)`,
          }}
        ></div>
        <div className="container-fluid p-0">
          <div className="row g-0 justify-content-end">
            <div className="col-xl-4 col-lg-12 tp-modify-width d-block">
              <div className="tp-section-title-sm-box tp-white-text grey-bg-3 hide-content">
                <span className="tp-section-subtitle-sm">
                  Servicios Integrales de Impresión
                </span>
                <h3 className="tp-section-title-sm tp-white-text-sm mb-5">
                  Soluciones Innovadoras para Proyectos Únicos
                </h3>
                <p>
                  Desde el diseño gráfico hasta la impresión final, ofrecemos soluciones completas para darle a tu mensaje la potencia visual que merece. Nuestros expertos trabajan contigo para crear impresiones que reflejan la calidad de tu negocio.
                </p>
                <Link className="tp-btn-sm" to="/services">
                  <span>
                    {/* SVG icons remain unchanged */}
                  </span>
                  Descubre Más
                </Link>
              </div>
              <div className="tp-section-title-sm-box tp-white-text black-bg hide-content">
                <span className="tp-section-subtitle-sm">
                  Calidad y Precisión en Cada Impresión
                </span>
                <h3 className="tp-section-title-sm tp-white-text-sm mb-5">
                  Compromiso con la Excelencia y el Detalle
                </h3>
                <p>
                  Con tecnología de vanguardia y un equipo de especialistas, garantizamos resultados impresos que superan tus expectativas. Permítenos llevar tus ideas al siguiente nivel con impresiones que dejan huella.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- feature area end   --> */}
    </>
  );
};

export default Feature;
