import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoPopup from "../../common/Modals/VideoPopup";

const Contact = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <>
      <div className="tp-sv-contact grey-bg-4 pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center pb-40">
                <span className="tp-section-subtitle mb-25"  style={{ color: '#0EFDF3', fontSize: '25px' }}>
                  Contáctanos
                </span>
                <h2 className="tp-section-title">
                  ¿No encuentras lo que buscas? <br />
                  ¡Contáctanos!
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-8">
              <div className="tp-contact-form tp-contact-form-2 white-bg pt-50 pl-50 pr-50 pb-55 mr-60">
                <h4 className="tp-contact-form-title">
                  Contacto Directo
                </h4>
                <form action="#">
                  <input
                    type="text"
                    name="name"
                    placeholder="Ingresa tu nombre"
                  />
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="Ingresa tu correo"
                  />
                  <br />
                  <textarea
                    name="message"
                    placeholder="Escribe tu mensaje"
                  ></textarea>
                  <button type="submit" className="tp-btn-border">
                    Enviar Mensaje
                    <span>
                      {/* SVG icons remain unchanged */}
                    </span>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tp-ct-info-box black-bg">
                <div className="tp-ct-info tp-ct-info-border pt-50 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{" "}
                    Contacto Online
                  </h3>
                  <p>
                    Calle de la Imprenta, 123 <br />
                    Ciudad Creativa, CP 45678 <br />
                    (+34) 123 456 789
                  </p>
                </div>
                <div className="tp-ct-info pt-60 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{" "}
                    Horario de Atención
                  </h3>
                  <p>
                    Horario de Oficina: 9AM - 6PM <br />
                    Cerrado los fines de semana
                  </p>
                </div>
                {/* Agregar enlaces a redes sociales si es necesario */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"} // Cambia según corresponda
      />
    </>
  );
};

export default Contact;
