import React, { useState } from "react";
import { Link } from "react-router-dom";

const ServiceAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <>
      <div className="tp-about-section black-bg p-relative">
        <div
          className="tp-ab-bg d-none d-lg-block"
          style={{ backgroundImage: `url("assets/img/service/service-bg.jpg")` }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-6">
              <div className="tp-ab-text pt-130 pl-150 p-relative">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-30">
                    Transformamos Tus Ideas
                  </span>
                  <h2 className="tp-section-title text-white">
                    Servicios de Impresión y Publicidad de Alta Calidad
                  </h2>
                  <p className="mb-30">
                    Desde pequeñas tiradas hasta grandes producciones, ofrecemos soluciones integrales de impresión y publicidad para llevar tu negocio al siguiente nivel. Nos especializamos en una amplia gama de servicios, incluyendo impresión digital y offset, gran formato, personalización de productos y mucho más.
                  </p>
                  <p>
                    Con un enfoque en la calidad y la innovación, nos aseguramos de que cada proyecto destaque. Ya sea que necesites papelería corporativa, materiales promocionales, textiles o productos personalizados, estamos aquí para ayudarte a crear impresiones que dejan huella.
                  </p>

                  <div className="mt-50 mb-130">
                    <Link to="/service" className="tp-btn-white">
                      Descubre Más
                      <span>
                        {/* SVG icons remain unchanged */}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default ServiceAbout;
