import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li >
        <Link to="/">Home</Link>
        
      </li>
      <li>
        <Link to="/about">Acerca de</Link>
      </li>
      <li className="">
        <Link to="/service">Servicios</Link>
      </li>
      <li className="">
        <Link to="/">Blog</Link>
        <ul className="submenu">
          <li>
            <Link to="/blog">blog</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/contact">Contacto</Link>
        
      </li>
    </ul>
  );
};

export default Menus;
